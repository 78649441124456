import React, { useEffect, useState } from "react";
import "./Add_Fund.css";
import DashHeader from "../DashHeader/DashHeader";
import DashFooter from "../DashFooter/DashFooter";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "../../Utils/API";
import { toast } from "react-toastify";
import { loadWeb3 } from "../../Utils/contract";
import { updateAuth } from "../../Redux/AuthSlice";

import {
  Abi_Main,
  Abi_Token,
  Abi_USDT,
  ContractAddress_Main,
  ContractAddress_Token,
  ContractAddress_USDT,
} from "../../Utils/ContractAddress";

import { Spinner } from "react-bootstrap";

const Add_Fund = () => {
  const user = useSelector((state) => state.Auth.userId);
  const jwt = useSelector((state) => state.Auth.jwtToken);
  let [Amount, setAmount] = useState(0);
  let [connected, setconnected] = useState("Wallet is locked");
  let [loading, setloading] = useState(false);
  let [USDT_Bal, setUSDT_Bal] = useState(0);
  let [Token_Bal, setToken_Bal] = useState(0);
  const [IsUpgrade, setIsUpgrade] = useState(0);
  const [TokenAmount, setTokenAmount] = useState(0);
  const [UsdtAmount, setUsdtAmount] = useState(0);
  const [Wallet_Address, setWallet_Address] = useState("");
  const [LiveRate, setLiveRate] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Loader component
  const Loader = () => (
    <div className="loader text-center">
      <Spinner animation="border" variant="primary" />
      <p>Loading...</p>
    </div>
  );

  const CalculateValue = (val) => {
    const Addvalues = val;
    const Totalvalue = parseFloat(Addvalues) + parseFloat(Amount);

    Getprice(Totalvalue);
  };

  const handleReset = () => {
    setAmount(0);
    setTokenAmount(0);
    setUsdtAmount(0);
  };

  const GetUserDetails = async () => {
    try {
      let responce = await API?.get(`userDetails?uid=${user}`, {
        headers: {
          Authorization: `${jwt}`,
          "Content-Type": "application/json", // Adjust the content type as needed
        },
      });
      responce = responce.data.data[0][0];
      console.log("getUserDetails", responce);

      setIsUpgrade(responce.CurrentPackage);
      setWallet_Address(responce.btcaddress);
    } catch (e) {
      console.log("userDetails error", e);
      // console.log("userDetails error", e.response.data);
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            wtcAuth: false,
            token: null,
            userId: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  const USDTBalance = async () => {
    try {
      let acc = await loadWeb3();
      let contract = await new window.web3.eth.Contract(
        Abi_USDT,
        ContractAddress_USDT
      );
      let result = await contract.methods.balanceOf(acc.toString()).call();
      result = parseFloat(result) / parseFloat(1000000000000000000);
      setUSDT_Bal(result.toFixed(2));
    } catch (error) {
      setloading(false);
      console.log(error);
    }
  };

  const TokenBalance = async () => {
    try {
      let acc = await loadWeb3();
      let contract = await new window.web3.eth.Contract(
        Abi_Token,
        ContractAddress_Token
      );
      let result = await contract.methods.balanceOf(acc.toString()).call();
      result = parseFloat(result) / parseFloat(1000000000000000000);
      setToken_Bal(result.toFixed(2));
    } catch (error) {
      setloading(false);
      console.log(error);
    }
  };

  const Getprice = async (amount) => {
    let Token_rate = 0;
    let BUSD_rate = 0;
    let contract = await new window.web3.eth.Contract(
      Abi_Main,
      ContractAddress_Main
    );

    console.log("ContractAddress_Main",ContractAddress_Main);
    let data = await contract.methods.getPrice(amount).call();
    console.log("getPrice",data);
    Token_rate = parseFloat(data[0]) / 1000000000000000000;
    let Token1toUSD = parseFloat(Token_rate) / parseFloat(20.0);
    console.log("Token_rate",Token1toUSD);
    
    BUSD_rate = amount*0.80 ;  //parseFloat(data[1]) / 1000000000000000000;

    setAmount(amount);
    setTokenAmount(Token_rate);
    setUsdtAmount(BUSD_rate);
  };

  const getPriceForLiveRate = async () => {
    let UsdValue = 100;
    let Token_rate = 0;
    let contract = await new window.web3.eth.Contract(
      Abi_Main,
      ContractAddress_Main
    );

    let data = await contract.methods.getPrice(UsdValue.toString()).call();

    console.log("getPriceForLiveRate",data);

    Token_rate = parseFloat(data[0]) / 1000000000000000000;

    let Token1toUSD = parseFloat(Token_rate) / parseFloat(20.0);
    let LiveRate = "1 USDT = " + Token1toUSD.toFixed(6).toString() + " METG";
    setLiveRate(LiveRate);
  };

  const walletConnected = async () => {
    try {
      let acc = await loadWeb3();

      if (acc == "No Wallet") {
        setconnected("Wallet is locked");
      } else if (acc == "Wrong Network") {
        toast.error(" Wrong Network Please Connect BSC Network");
        setconnected("Wrong Network");
      } else {
        setconnected("Wallet is Connected");
      }
    } catch (e) {
      console.log("Error While WalletConnect", e);
    }
  };

  const Activate = async (e) => {
    let acc = await loadWeb3();
    setloading(true);
    if (acc === "No Wallet" || acc === undefined) {
      toast.error("No Wallet Connected");
      setloading(false);
    } else if (acc == "Wrong Network") {
      console.log("Wrong Network Please Connect BSc Scan Network");
      toast.error("Wrong Network Please Connect BSc Scan Network");
      setloading(false);
    } else {
      if (Wallet_Address.toUpperCase() == acc.toUpperCase()) {
        if (Amount !== 0) {
          if (USDT_Bal < UsdtAmount) {
            toast.error("Insufficient USDT Balance");
            setloading(false);
          } else if (Token_Bal < TokenAmount) {
            toast.error("Insufficient Rags Token");
            setloading(false);
          } else {
            // let amountValue = window.web3.utils.toWei(Amount.toString()); // parseFloat(Amount) * 1000000000000000000; // window.web3.utils.fromWei(Amount.toString());

            let PlanAmountValue = window.web3.utils.toWei(
              Amount.toString(),
              "ether"
            );

            let UsdtAmountNew = parseFloat(UsdtAmount) + parseFloat(1);
            let usdtamountValue = window.web3.utils.toWei(
              UsdtAmountNew.toString(),
              "ether"
            );

            let TokenAmountNew = parseFloat(TokenAmount) + parseFloat(1);
            let tokenamountValue = TokenAmountNew * 1000000000000000000;

            // let tokenamountValue = window.web3.utils.toWei(
            //   TokenAmountNew.toString(),
            //   "ether"
            // );

            const gasPrice = await window.web3.eth.getGasPrice();
            const approveBlock = window.web3.eth.getBlock("latest");

            try {
              let contract_Main = await new window.web3.eth.Contract(
                Abi_Main,
                ContractAddress_Main
              );
              let contract_USDT = await new window.web3.eth.Contract(
                Abi_USDT,
                ContractAddress_USDT
              );
              let contract_Token = await new window.web3.eth.Contract(
                Abi_Token,
                ContractAddress_Token
              );
              let approveCall = await contract_USDT.methods
                .approve(ContractAddress_Main, usdtamountValue)
                .send({
                  from: acc,
                  gasLimit: approveBlock.gasLimit,
                  gasPrice: await window.web3.eth.getGasPrice(),
                });
              toast.success("USDT Approved Successful");

              let approveCall_Token = await contract_Token.methods
                .approve(ContractAddress_Main, tokenamountValue)
                .send({
                  from: acc,
                  gasLimit: approveBlock.gasLimit,
                  gasPrice: await window.web3.eth.getGasPrice(),
                });
              toast.success("Token Approved Successful");

              let sellCall = await contract_Main.methods
                .buyRouter(Amount)
                .send({
                  from: acc,
                  gasLimit: approveBlock.gasLimit,
                  gasPrice: await window.web3.eth.getGasPrice(),
                });

              // Post transaction details to API
              let res = await API.post(
                "activation",
                {
                  uid: user,
                  walletAddress: acc,
                  usd: Amount,
                  token: TokenAmount,
                  bnb: UsdtAmount,
                  txn: sellCall.transactionHash,
                },
                {
                  headers: {
                    Authorization: `${jwt}`,
                    "Content-Type": "application/json", // Adjust the content type as needed
                  },
                }
              );
              console.log("activation", res.data);
              toast.success("Transaction Successful");
              if (res.data.success === true) {
                toast.success("Activation is under process");
                navigate("/dashboard");
              } else {
                toast.error(res.data.Result);
              }
            } catch (err) {
              console.log("error while calling function sell", err);
              if (
                err.response &&
                err.response.data &&
                err.response.data.showableMessage ===
                  "Please login to access this resource"
              ) {
                dispatch(
                  updateAuth({
                    isAuth: false,
                    userId: null,
                    jwtToken: null,
                    ipAddress: null,
                  })
                );
                navigate("/");
              } else {
                toast.error("Transaction failed: " + (err.message || err));
              }
            }
          }
        } else {
          toast.error("Please Enter Amount First");
          setloading(false);
        }
      } else {
        toast.error("Invalid Wallet address");
        setloading(false);
      }
    }
  };

  const Upgrade = async (e) => {
    let acc = await loadWeb3();
    setloading(true);
    if (acc === "No Wallet" || acc === undefined) {
      toast.error("No Wallet Connected");
      setloading(false);
    } else if (acc == "Wrong Network") {
      console.log("Wrong Network Please Connect Polygon Scan Network");
      setloading(false);
    } else {
      if (Wallet_Address.toUpperCase() == acc.toUpperCase()) {
        if (Amount !== 0) {
          if (parseInt(IsUpgrade) > parseInt(Amount)) {
            toast.error("You cannot upgrade with lower package");
            setloading(false);
          } else if (USDT_Bal < UsdtAmount) {
            toast.error("Insufficient USDT Balance");
            setloading(false);
          } else if (Token_Bal < TokenAmount) {
            toast.error("Insufficient Rags Token");
            setloading(false);
          } else {
            // let amountValue = window.web3.utils.toWei(Amount.toString()); // parseFloat(Amount) * 1000000000000000000; // window.web3.utils.fromWei(Amount.toString());

            let PlanAmountValue = window.web3.utils.toWei(
              Amount.toString(),
              "ether"
            );

            let UsdtAmountNew = parseFloat(UsdtAmount) + parseFloat(1);
            let usdtamountValue = window.web3.utils.toWei(
              UsdtAmountNew.toString(),
              "ether"
            );

            let TokenAmountNew = parseFloat(TokenAmount) + parseFloat(1);
            let tokenamountValue = TokenAmountNew * 10 ** 18;

            // let tokenamountValue = window.web3.utils.toWei(
            //   TokenAmountNew.toString(),
            //   "ether"
            // );

            const gasPrice = await window.web3.eth.getGasPrice();
            const approveBlock = window.web3.eth.getBlock("latest");

            try {
              let contract_Main = await new window.web3.eth.Contract(
                Abi_Main,
                ContractAddress_Main
              );
              let contract_USDT = await new window.web3.eth.Contract(
                Abi_USDT,
                ContractAddress_USDT
              );
              let contract_Token = await new window.web3.eth.Contract(
                Abi_Token,
                ContractAddress_Token
              );
              let approveCall = await contract_USDT.methods
                .approve(ContractAddress_Main, usdtamountValue)
                .send({
                  from: acc,
                  gasLimit: approveBlock.gasLimit,
                  gasPrice: await window.web3.eth.getGasPrice(),
                });
              toast.success("USDT Approved Succesfull");

              let approveCall_Token = await contract_Token.methods
                .approve(ContractAddress_Main, tokenamountValue)
                .send({
                  from: acc,
                  gasLimit: approveBlock.gasLimit,
                  gasPrice: await window.web3.eth.getGasPrice(),
                });
              toast.success("Token Approved Succesfull"+ Amount);

              let sellCall = await contract_Main.methods
                .buyRouter(Amount)
                .send({
                  from: acc,
                  gasLimit: approveBlock.gasLimit,
                  gasPrice: await window.web3.eth.getGasPrice(),
                });
              // Post transaction details to API
              let res = await API.post(
                "upgradation",
                {
                  uid: user,
                  walletAddress: acc,
                  usd: Amount,
                  token: TokenAmount,
                  bnb: UsdtAmount,
                  txn: sellCall.transactionHash,
                },
                {
                  headers: {
                    Authorization: `${jwt}`,
                    "Content-Type": "application/json", // Adjust the content type as needed
                  },
                }
              );
              console.log("upgrade", res.data);
              toast.success("Transaction Successful");
              if (res.data.success == true) {
                toast.success("Upgrade is under process");
                navigate("/dashboard");
              } else {
                toast.error(res.data.Result);
              }
            } catch (err) {
              console.log("error while calling function sell", err);
              if (
                err.response &&
                err.response.data &&
                err.response.data.showableMessage ===
                  "Please login to access this resource"
              ) {
                dispatch(
                  updateAuth({
                    isAuth: false,
                    userId: null,
                    jwtToken: null,
                    ipAddress: null,
                  })
                );
                navigate("/");
              } else {
                toast.error("Transaction failed: " + (err.message || err));
                setloading(false);
              }
            }
          }
        } else {
          toast.error("Please Enter Amout First");
          setloading(false);
        }
      } else {
        toast.error("Invalid Wallet address");
        setloading(false);
      }
    }
  };

  useEffect(() => {
    getPriceForLiveRate();
    GetUserDetails();

    setInterval(walletConnected, 5000); // 5000 milliseconds = 5 seconds
    setInterval(TokenBalance, 5000); // 5000 milliseconds = 5 seconds
    setInterval(USDTBalance, 5000); // 5000 milliseconds = 5 seconds
  }, []);

  return (
    <div>
      <DashHeader />
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row mx-0  py-3">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-16 text-white fw-bold">Activate/Upgrade</div>
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                  <div className="breadcrumb-title pe-3">Activate/Upgrade</div>
                  <div className="ps-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                          <a href="">
                            <i className="bx bx-home-alt text-white" />
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Activate/Upgrade
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3" />
            <div className="col-lg-6">
              <div className="modal-dialog">
                <div className="modal-content exchange_width">
                  <div className="modal-header exchange_hd w-100">
                    <h5 className="w-100 text-left pt-4">
                      Available METG : {Token_Bal} METG<br></br>
                      Available USDT : {USDT_Bal} USDT
                    </h5>
                  </div>
                  <div className="modal-header exchange_hd w-100">
                    <h5 className="w-100 text-left pt-4">
                      Live Rate : {LiveRate}
                    </h5>
                  </div>
                  <div className="modal-body">
                    <div className="box box-default table-wrapper ng-pristine ng-valid ng-valid-maxlength mt-0">
                      <div className="panel-body">
                        <div className="row align-items-center justify-content-between">
                          <div className="col-sm-4 col-6">
                            <label>Package Amount</label>
                          </div>
                          <div className="col-sm-8 col-6">
                            <input
                              type="text"
                              className="form-control mb-20 shadow-none"
                              id="amountInput"
                              defaultValue={0}
                              value={Amount}
                              readOnly
                              style={{ width: "94%" }}
                            />
                          </div>
                        </div>
                        <div className="row align-items-center justify-content-between">
                          <div className="col-sm-4 col-6">
                            <label>METG Coin</label>
                          </div>
                          <div className="col-sm-8 col-6">
                            <input
                              type="text"
                              className="form-control mb-20 shadow-none"
                              id="amountInput"
                              defaultValue={0}
                              value={TokenAmount}
                              readOnly
                              style={{ width: "94%" }}
                            />
                          </div>
                        </div>
                        <div className="row align-items-center justify-content-between">
                          <div className="col-sm-4 col-6">
                            <label>USDT</label>
                          </div>
                          <div className="col-sm-8 col-6">
                            <input
                              type="text"
                              className="form-control mb-20 shadow-none"
                              id="amountInput"
                              defaultValue={0}
                              value={UsdtAmount}
                              readOnly
                              style={{ width: "94%" }}
                            />
                          </div>
                        </div>

                        <div className="row justify-content-center">
                          <div
                            className="col-12 mt-3 mb-3 text-center"
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              justifyContent: "space-evenly",
                              gap: "10px",
                            }}
                          >
                            {/* <div
                              className="btn btn-Green py-2 px-3 paymentadd"
                              onClick={() => CalculateValue(10)}
                            >
                              $ 10
                            </div> */}

                            <div
                              className="btn btn-Green py-2 px-3 paymentadd"
                              onClick={() => CalculateValue(100)}
                            >
                              $ 100
                            </div>
                            <div
                              className="btn btn-Green py-2 px-3 paymentadd"
                              onClick={() => CalculateValue(500)}
                            >
                              $ 500
                            </div>
                            <div
                              className="btn btn-Green py-2 px-3 paymentadd"
                              onClick={() => CalculateValue(1000)}
                            >
                              $ 1000
                            </div>
                            <div
                              className="btn btn-Green py-2 px-3 paymentadd"
                              onClick={() => CalculateValue(5000)}
                            >
                              $ 5000
                            </div>
                            <div
                              className="btn btn-Green py-2 px-3 restart"
                              onClick={() => handleReset()}
                            >
                              Reset
                            </div>
                          </div>
                        </div>
                        <div className="row align-items-center justify-content-center mx-0">
                          <div className="col-sm-6 row align-items-center justify-content-center">
                            {loading == true ? (
                              <>
                                <Loader />
                              </>
                            ) : (
                              <>
                                {parseFloat(IsUpgrade) > 0 ? (
                                  <button
                                    className="btn btn-theme1 withdraw_btn w-100 py-sm-3 py-2 shadow-none"
                                    onClick={() => Upgrade()}
                                  >
                                    Upgrade
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-theme1 withdraw_btn w-100 py-sm-3 py-2 shadow-none"
                                    onClick={() => Activate()}
                                  >
                                    Activate
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                          <div className="col-12 ">
                            <p className="text-white fs-16 fw-bold text-center py-2 mb-0 notetext">
                              Note: Activate/Upgrade Amount Should Be Multiple
                              of $100.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>
      </div>
      <DashFooter />
    </div>
  );
};

export default Add_Fund;
