import React, { useEffect, useState } from "react";
import DashFooter from "../DashFooter/DashFooter";
import DashHeader from "../DashHeader/DashHeader";
import { updateAuth } from "../../Redux/AuthSlice";
import { toast } from "react-toastify";
import { loadWeb3 } from "../../Utils/contract";
import { API } from "../../Utils/API";
import DOMPurify from "dompurify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    Abi_Main,
    Abi_Token,
    Abi_USDT,
    ContractAddress_Main,
    ContractAddress_Token,
    ContractAddress_USDT
} from "../../Utils/ContractAddress";

const RoiWithdrawal = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.Auth.userId);
    const jwt = useSelector((state) => state.Auth.jwtToken);

    let [Amount, setAmount] = useState("");

    let [spiner, setspiner] = useState(false);
    const [Wallet_Address, setWallet_Address] = useState("");
    const [loading, setloading] = useState(false);
    const [TopupStatus, setTopupStatus] = useState("0");
    let [USDT, setUSDT] = useState(0);
    let [connected, setconnected] = useState("Wallet is locked");
    const [LiveRate, setLiveRate] = useState("");
    const [Token_rate, setToken_rate] = useState(0);

    const [token, setToken] = useState(0);
    const [Fees, setFees] = useState(0);
    const [NetPayable, setNetPayable] = useState(0);
    const [NetPayableToken, setNetPayableToken] = useState(0);
    const [NetPayableUSDT, setNetPayableUSDT] = useState(0);

    const GetUserDetails = async () => {
        try {
            let responce = await API?.get(`userDetails?uid=${user}`, {
                headers: {
                    Authorization: `${jwt}`,
                    "Content-Type": "application/json",
                },
            });
            // console.log("responce", responce);
            responce = responce.data.data[0][0];
            // console.log("getUserDetails", responce);

            setWallet_Address(responce.btcaddress);
            setTopupStatus(responce.top_up);
            setUSDT(responce.Net_Balance_Roi);
        } catch (e) {
            //  console.log("userDetails error", e);
            // console.log("userDetails error", e.response.data);
            if (
                e.response.data.showableMessage ===
                "Please login to access this resource"
            ) {
                dispatch(
                    updateAuth({
                        isAuth: false,
                        userId: null,
                        jwtToken: null,
                        ipAddress: null,
                    })
                );
                navigate("/");
            }
        }
    };

    const getPriceForLiverate = async () => {
        let usdvalue = 100;
        let Token_rate = 0;
        let contract = await new window.web3.eth.Contract(
            Abi_Main,
            ContractAddress_Main
        );

        let data = await contract.methods.getFWTPrice(usdvalue.toString()).call();
        console.log("WithdrawalTOkenRate-->", data);
        Token_rate = parseFloat(data) / (100);

        let Token1toUSD = Token_rate;
        let LiveRate = "1 USDT = " + Token1toUSD.toFixed(6).toString() + " FWT";
        setToken_rate(Token1toUSD);
        setLiveRate(LiveRate);

    };


    const WalletConnected = async () => {
        try {
            let acc = await loadWeb3();

            if (acc === "No Wallet") {
                setconnected("Wallet is locked");
            } else if (acc === "Wrong Network") {
                toast.error(" Wrong Network Please Connect BSC Network");
                setconnected("Wrong Network");
            } else {
                setconnected("Wallet is Connected");
            }
        } catch (e) {
            console.log("Error While WalletConnect", e);
        }
    };

    const CalculateValue = async (val) => {
        const Selvalues = DOMPurify.sanitize(
            val.replace(/[^0-9]/gi, "").substring(0, 6)
        );
        setAmount(Selvalues);
        setFees(2.00);
        setNetPayable((Selvalues - 2).toFixed(4));

        setNetPayableUSDT((Selvalues - 2).toFixed(4));
        setNetPayableToken(((Selvalues - 2) * 1 * Token_rate).toFixed(4))
    };

    // const calculateToken = async (value) => {
    //   try {
    //     let responce = await API?.get(
    //       `getTokenValue?usdvalue=${value || 0}&type=Buy`,
    //       {
    //         headers: {
    //           Authorization: `${jwt}`,
    //           "Content-Type": "application/json",
    //         },
    //       }
    //     );

    //     console.log("resp", responce?.data?.data[0][0]?.Token);
    //     setToken(responce?.data?.data[0][0]?.Token);
    //   } catch (e) {
    //     if (
    //       e.response.data.showableMessage ===
    //       "Please login to access this resource"
    //     ) {
    //       dispatch(
    //         updateAuth({
    //           isAuth: false,
    //           userId: null,
    //           jwtToken: null,
    //           ipAddress: null,
    //         })
    //       );
    //       navigate("/");
    //     }
    //   }
    // };

    const WithdrawalUser = async (tstatus) => {
        try {
            if (user === undefined || user === "" || user === null) {
                navigate("/");
            }
            let acc = await loadWeb3();
            if (acc === "No Wallet" || acc === undefined) {
                toast.error("No Wallet");
            } else if (acc == "Wrong Network") {
                //toast.error("Wrong Network Please Connect BSC Scan Network");
                console.log("Wrong Network Please Connect Polygon Scan Network");
            } else {
                if (Wallet_Address.toUpperCase() == acc.toUpperCase()) {
                    if (Amount !== 0) {
                        if (parseFloat(USDT) < parseFloat(Amount)) {
                            toast.error("Insufficient USDT Balance");
                            setspiner(false);
                            return;
                        } else {
                            setspiner(true);
                        }

                        if (parseFloat(Amount) < parseFloat(10)) {
                            toast.error("You can wihdrawal minimum 10 USDT");
                            setspiner(false);
                            return;
                        } else {
                            setspiner(true);
                        }

                        let res = await API.post(
                            "/withdrawal",
                            {
                                uid: user,
                                usd: Amount,
                                token: NetPayableToken,
                                bnb: NetPayableUSDT,
                                type: "Roibalance"
                            },
                            {
                                headers: {
                                    Authorization: `${jwt}`,
                                    "Content-Type": "application/json",
                                },
                            }
                        );

                        // console.log("res-->", res);
                        if (res.data.data === "SUCCESSFUL") {
                            toast.success("Withdrawal Successful !!!");
                            GetUserDetails();
                            setAmount(0);
                            setToken(0);
                            setspiner(false);
                        }
                        else {
                            toast.error(res.data.data);
                            setspiner(false);
                        }

                        setspiner(false);
                    }
                } else {
                    toast.error("Invalid Wallet address");
                }
            }
        } catch (err) {
            // console.log("error while calling fuction sell", err);
            if (
                err.response.data.showableMessage ===
                "Please login to access this resource"
            ) {
                dispatch(
                    updateAuth({
                        isAuth: false,
                        userId: null,
                        jwtToken: null,
                        ipAddress: null,
                    })
                );
                navigate("/");
            }
            setspiner(false);
        }
    };

    useEffect(() => {
        WalletConnected();
        getPriceForLiverate();
        GetUserDetails();
    }, []);

    return (
        <div>
            <DashHeader />
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="row mx-0  py-3">
                        <div className="col-12">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="fs-18 text-white fw-bold">ROI Withdrawal</div>
                                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                                    <div className="breadcrumb-title pe-3">Withdrawal</div>
                                    <div className="ps-3">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb mb-0 p-0">
                                                <li className="breadcrumb-item">
                                                    <a href="">
                                                        <i className="bx bx-home-alt text-white" />
                                                    </a>
                                                </li>
                                                <li
                                                    className="breadcrumb-item active"
                                                    aria-current="page"
                                                >
                                                    ROI Withdrawal
                                                </li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3" />
                        <div className="col-md-6">
                            <div className="modal-dialog">
                                <div className="modal-content exchange_width">
                                    <div className="modal-header exchange_hd w-100 flex-wrap ">
                                        <h4 className="w-100 text-center pt-2">
                                            Wallet is Connected
                                        </h4>
                                        <p className="text-white fs-16 text-center w-100">
                                            Live Rate : {LiveRate}
                                        </p>
                                    </div>
                                    <div className="modal-body">
                                        <form
                                            className="box box-default table-wrapper ng-pristine ng-valid ng-valid-maxlength mt-0"
                                        >
                                            <div className="panel-body">
                                                <div className="row align-items-center justify-content-center">
                                                    <div className="col-md-4 col-6">
                                                        <label>Wallet Address </label>
                                                    </div>
                                                    <div className="col-md-8 col-6">
                                                        <input
                                                            type="text"
                                                            className="form-control mb-20  shadow-none"
                                                            readOnly
                                                            value={Wallet_Address}
                                                            style={{ width: "90%" }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row align-items-center justify-content-center">
                                                <div className="col-md-5 col-6">
                                                        <label>Enter USDT</label>
                                                    </div>
                                                    <div className="col-md-7 col-6">
                                                        <input
                                                            type="text"
                                                            className="form-control mb-20  shadow-none"
                                                            id="amountInput"
                                                            min={1}
                                                            step={1}
                                                            maxLength={4}
                                                            value={Amount}
                                                            onChange={(e) => CalculateValue(e.target.value)}
                                                            style={{ width: "90%" }}
                                                            placeholder="Enter Amount in USDT"
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className="row align-items-center justify-content-center"
                                                >
                                                    <div className="col-md-5 col-6">
                                                        <label>Fees USDT</label>
                                                    </div>
                                                    <div className="col-md-7 col-6">
                                                        <input
                                                            type="text"
                                                            className="form-control mb-20  shadow-none"
                                                            id="amountInput1"
                                                            value={Fees}
                                                            readOnly
                                                            style={{ width: "90%" }}
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className="row align-items-center justify-content-center"
                                                    style={{ display: "none" }}
                                                >
                                                    <div className="col-md-5 col-6">
                                                        <label>Net Payable </label>
                                                    </div>
                                                    <div className="col-md-7 col-6">
                                                        <input
                                                            type="text"
                                                            className="form-control mb-20  shadow-none"
                                                            id="amountInput1"
                                                            value={NetPayable}
                                                            readOnly
                                                            style={{ width: "90%" }}
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className="row align-items-center justify-content-center"
                                                >
                                                    <div className="col-md-5 col-6">
                                                        <label>Net USDT </label>
                                                    </div>
                                                    <div className="col-md-7 col-6">
                                                        <input
                                                            type="text"
                                                            className="form-control mb-20  shadow-none"
                                                            id="amountInput1"
                                                            value={NetPayableUSDT}
                                                            readOnly
                                                            style={{ width: "90%" }}
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className="row align-items-center justify-content-center"
                                                >
                                                    <div className="col-md-5 col-6">
                                                        <label>Net Payable (FWT) </label>
                                                    </div>
                                                    <div className="col-md-7 col-6">
                                                        <input
                                                            type="text"
                                                            className="form-control mb-20  shadow-none"
                                                            id="amountInput1"
                                                            value={NetPayableToken}
                                                            readOnly
                                                            style={{ width: "90%" }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row align-items-center justify-content-center mx-0 pt-3">
                                                    <div className="col-6 row align-items-center justify-content-center">
                                                        {parseInt(TopupStatus) === 1 && (
                                                            <button
                                                                className="btn btn-theme1 withdraw_btn w-100 py-3 shadow-none"
                                                                type="button"
                                                                onClick={() => {
                                                                    WithdrawalUser();
                                                                }}
                                                            >
                                                                Withdrawal
                                                            </button>
                                                        )}
                                                    </div>
                                                    <div className="col-12 ">
                                                        <p className="text-white fs-16 fw-bold text-center py-2 mb-0 notetext">
                                                            Note: You can withdrawal minimum $10.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DashFooter />
        </div>
    );
};

export default RoiWithdrawal;
