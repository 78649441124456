import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import Roadmap from "./Roadmap";
import Popup from "./Popup";

const Hero = () => {
  
  return (
   
      
    <div>
      
      {/* <Popup /> */}
      <Header />
      <section className="tf-slider home3">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="swiper-container slider-home ">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="slider-item">
                      <div className="tf-slider-item style-3">
                        <div className="content-inner">
                          <h2 className="heading mb0">
                          Empowering Connections, Unlocking Rewards – Join <br />
                            <span className="animationtext clip">
                              {/* <span className="cd-words-wrapper">
                                <span className="item-text is-visible"></span>
                                <span className="item-text is-hidden">NEXA GLOBAL's</span>
                                <span className="item-text is-hidden">NEXA GLOBAL's</span>
                              </span> */}
                              NEXA GLOBAL's <br />
                            </span>
                            Community
                          </h2>
                          {/* <h1 className="heading">  WORLD’S MOST ADVANCED CRYPTO COIN </h1> */}
                          <p className="sub-heading">
                          The Future of Wealthy Life Starts from here.
                          </p>
                          <div className="btn-slider ">
                            <a
                              href="#"
                              className="tf-button "
                              data-toggle="modal"
                              data-target="#popup_bid"
                            >
                              CONNECT WALLET
                            </a>
                            <a href="#" className="tf-button style-2">
                              WHITELIST NOW
                            </a>
                          </div>
                        </div>
                        <div className="image">
                          <img
                            src="./assets/image/home/nft-gif.png"
                            alt="Image"
                            className="img ani5"
                          />
                          <img
                            src="./assets_front/images/slider/slider-7.png"
                            alt="Image"
                            className="ani4 img-1"
                          />
                          <img
                            src="./assets_front/images/slider/slider-6.png"
                            alt="Image"
                            className="ani5 img-2"
                          />
                        </div>
                      </div>
                    </div>
                    {/* item*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tf-about-ss tf-collection-ss" id="about">
        <div className="icon">
          <svg
            width={254}
            height={426}
            viewBox="0 0 254 426"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_f_2258_552)">
              <path
                d="M162.226 50.3816L109.596 154.133L220.194 117.978L241.73 183.858L131.132 220.012L234.883 272.642L203.529 334.452L99.7779 281.822L135.932 392.42L70.0528 413.956L33.8982 303.358L-18.7318 407.109L-80.541 375.755L-27.911 272.004L-138.509 308.158L-160.045 242.279L-49.4471 206.124L-153.198 153.494L-121.844 91.6849L-18.0931 144.315L-54.2477 33.7168L11.632 12.1807L47.7866 122.779L100.417 19.0276L162.226 50.3816Z"
                fill="url(#paint0_linear_2258_552)"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_2258_552"
                x="-172.045"
                y="0.180664"
                width="425.775"
                height="425.775"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation={6}
                  result="effect1_foregroundBlur_2258_552"
                />
              </filter>
              <linearGradient
                id="paint0_linear_2258_552"
                x1="-137.521"
                y1="122.589"
                x2="219.206"
                y2="303.547"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset={0} stopColor="var(--primary-color35)" />
                <stop offset={1} stopColor="var(--primary-color35)" stopOpacity={0} />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="tf-container2">
          <div className="row">
            <div className="col-md-12">
              <div
                className="title-ss wow fadeInUp"
                style={{ visibility: "visible", animationName: "fadeInUp" }}
              >
                <h3>ABOUT US</h3>
                <p>
                NEXA GLOBAL is a community-driven platform designed to empower individuals through the Future World Token, our native cryptocurrency designed for wealth creation and growth. Our innovative referral-based program is crafted to maximize returns for our community, providing multiple income streams and substantial rewards for those who invest and refer others to the NEXA GLOBAL ecosystem.
                </p>
                <p>We believe in creating a sustainable wealth-building environment, where every user, whether an investor or a promoter, benefits from the strength of the network. By rewarding referrals and encouraging active participation, NEXA GLOBAL aims to elevate financial empowerment for every associate, helping build a global community where wealth is shared, success is celebrated, and every connection drives mutual growth.</p>
                <p>Join us in shaping the future of decentralized wealth creation with the Future World Token – where your network is your wealth.</p>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 mb-5">
              <div
                className="tf-step style2  wow fadeInUp"
                style={{ visibility: "visible", animationName: "fadeInUp" }}
              >
                <div className="step-title">
                  <div className="sub-number">01</div>
                  <h3>Security
                  </h3>
                </div>
                <p>
                NEXA GLOBAL ensures top-tier security with advanced blockchain technology, high-level encryption, and rigorous audits, providing a safe, trusted platform for growing wealth with Future World Token.{" "}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 mb-5">
              <div
                className="tf-step style2 wow fadeInUp"
                style={{ visibility: "visible", animationName: "fadeInUp" }}
              >
                <div className="step-title">
                  <div className="sub-number">02</div>
                  <h3>User Friendly</h3>
                </div>
                <p>
                NEXA GLOBAL offers a clean, user-friendly interface that makes managing investments and referrals simple, even for beginners. Our intuitive design ensures easy navigation, so users can focus on growing their wealth without technical barriers.{" "}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 mb-5">
              <div
                className="tf-step style2 wow fadeInUp"
                style={{ visibility: "visible", animationName: "fadeInUp" }}
              >
                <div className="step-title">
                  <div className="sub-number">03</div>
                  <h3>Community Driven
                  </h3>
                </div>
                <p>
                NEXA GLOBAL is a community-centered platform that rewards active participation, fostering shared wealth and mutual support. By prioritizing user growth and collaboration, we’re redefining crypto as a community-driven success model.{" "}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 mb-5">
              <div
                className="tf-step style2 wow fadeInUp"
                style={{ visibility: "visible", animationName: "fadeInUp" }}
              >
                <div className="step-title">
                  <div className="sub-number">04</div>
                  <h3>Scalability 
                  </h3>
                </div>
                <p>
                NEXA GLOBAL is built to support rapid, competitive scaling, enabling users to maximize their growth potential as the Future World Token network expands. Our dynamic referral program is structured to accelerate community growth and offer increasing rewards, so that every user benefit from the platform’s success. With scalable opportunities and performance-driven incentives, NEXA GLOBAL empowers users to grow their investments and leverage the full power of our expanding ecosystem. 

.{" "}
                </p>
              </div>
            </div>
            <div
              className="col-md-12 wow fadeInUp mt-3"
              style={{ visibility: "visible", animationName: "fadeInUp" }}
            >
              <div className="btn">
                <a href="#" className="tf-button style-2">
                  READ MORE
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tf-collection-ss">
        <div className="tf-container d-none">
          <div className="row">
            <div className="col-md-3">
                <div className="slider-item">
                      <div className="tf-product">
                        <div className="image">
                          <img
                            src="assets_front/images/product/product23.jpg"
                            alt="Image"
                          />
                        </div>
                        <h6 className="name">
                          <a href="#" className="text-white">SPOTIOR #03</a>
                        </h6>
                      </div>
                </div>
            </div>

            <div className="col-md-3">
                <div className="slider-item">
                      <div className="tf-product">
                        <div className="image">
                          <img
                            src="assets_front/images/product/product24.jpg"
                            alt="Image"
                          />
                        </div>
                        <h6 className="name">
                          <a href="#" className="text-white">SKISIRS#2</a>
                        </h6>
                      </div>
                </div>
            </div>

            <div className="col-md-3">
                   <div className="slider-item">
                      <div className="tf-product">
                        <div className="image">
                          <img
                            src="assets_front/images/product/product25.jpg"
                            alt="Image"
                          />
                        </div>
                        <h6 className="name">
                          <a href="#" className="text-white">SKISIRS#2</a>
                        </h6>
                      </div>
                    </div>
            </div>
            <div className="col-md-3">
                   <div className="slider-item">
                      <div className="tf-product">
                        <div className="image">
                          <img
                            src="assets_front/images/product/product20.jpg"
                            alt="Image"
                          />
                        </div>
                        <h6 className="name">
                          <a href="#" className="text-white">SKISIRS#2</a>
                        </h6>
                      </div>
                    </div>
            </div>
            <div className="col-md-3">
                   <div className="slider-item">
                      <div className="tf-product active">
                        <div className="image">
                          <img
                            src="assets_front/images/product/product21.jpg"
                            alt="Image"
                          />
                        </div>
                        <h6 className="name">
                          <a href="#" className="text-white">SKELATON #01</a>
                        </h6>
                      </div>
                    </div>

            </div>
            <div className="col-md-3">
                <div className="slider-item">
                      <div className="tf-product">
                        <div className="image">
                          <img
                            src="assets_front/images/product/product22.jpg"
                            alt="Image"
                          />
                        </div>
                        <h6 className="name">
                          <a href="#" className="text-white">MEECAT #02</a>
                        </h6>
                      </div>
                    </div>
            </div>
            <div className="col-md-3">
                    <div className="slider-item">
                      <div className="tf-product">
                        <div className="image">
                          <img
                            src="assets_front/images/product/product2.jpg"
                            alt="Image"
                          />
                        </div>
                        <h6 className="name">
                          <a href="#" className="text-white">SPOTIOR #03</a>
                        </h6>
                      </div>
                    </div>
            </div>
            <div className="col-md-3">
                  <div className="slider-item">
                      <div className="tf-product">
                        <div className="image">
                          <img
                            src="assets_front/images/product/product4.jpg"
                            alt="Image"
                          />
                        </div>
                        <h6 className="name">
                          <a href="#" className="text-white">SKISIRS#2</a>
                        </h6>
                      </div>
                    </div>
            </div>
          </div>
         
        </div>
        <div className="tf-container">
          <div className="row align-items-center">
            <div className="col-12 text-center">
              <h3 className="text-white headingD">What is <span> Nexa Global</span></h3>
            </div>
            <div className="col-md-6">
              <div className="iamge">
                <img className="w-100 h-100" src="/assets/image/uo_bg.png" alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <p className="perographD">
              NEXA GLOBAL leverages cutting-edge blockchain technology to deliver a secure, scalable, and efficient platform cantered on the Future World Token. Our advanced infrastructure ensures transparency, high-speed transactions, and robust security. The Future World Token empowers users with diverse financial opportunities, driving long-term growth. Through technical innovation, NEXA GLOBAL sets new standards in decentralized finance for trust and community-driven wealth creation.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="tf-work-ss tf-collection-ss" id="Benifits">
          <div className="tf-container">
            <div className="row">
              <div className="col-md-12">
                <div
                  className="title-ss wow fadeInUp"
                  style={{ visibility: "visible", animationName: "fadeInUp" }}
                >
                  <h3>
                    HOW TO <span>NEXA GLOBAL</span> WORK
                  </h3>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 ">
                <div
                  className="tf-work style-2  wow fadeInUp"
                  style={{ visibility: "visible", animationName: "fadeInUp" }}
                >
                  <div className="image">
                    <img id="work-5" src="assets_front/images/svg/work-5.svg" alt="Image" />
                  </div>
                  <h5 className="step">STEP 1</h5>
                  <h4 className="title">
                    <a href="#" className="text-white">Connect Your Wallet</a>
                  </h4>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 ">
                <div
                  className="tf-work style-2 wow fadeInUp"
                  style={{ visibility: "visible", animationName: "fadeInUp" }}
                >
                  <div className="image">
                    <img id="work-6" src="assets_front/images/svg/work-6.svg" alt="Image" />
                  </div>
                  <h5 className="step">STEP 2</h5>
                  <h4 className="title">
                    <a href="#"className="text-white">Select Your Quantity</a>
                  </h4>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 ">
                <div
                  className="tf-work style-2 wow fadeInUp"
                  style={{ visibility: "visible", animationName: "fadeInUp" }}
                >
                  <div className="image">
                    <img id="work-7" src="assets_front/images/svg/work-7.svg" alt="Image" />
                  </div>
                  <h5 className="step">STEP 3</h5>
                  <h4 className="title">
                    <a href="#" className="text-white">Confirm  the Transaction</a>
                  </h4>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 ">
                <div
                  className="tf-work style-2 mb30 wow fadeInUp"
                  style={{ visibility: "visible", animationName: "fadeInUp" }}
                >
                  <div className="image">
                    <img id="work-8" src="assets_front/images/svg/work-8.svg" alt="Image" />
                  </div>
                  <h5 className="step">STEP 4</h5>
                  <h4 className="title">
                    <a href="#" className="text-white">Receive Your Crypto</a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
      </section>

      <section className="tf-section tf-collection-ss team tf-team-ss">
          <div className="icon">
            <svg
              width={250}
              height={473}
              viewBox="0 0 250 473"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_f_2673_3126)">
                <path
                  d="M149.737 53.4772L90.521 170.211L214.959 129.532L239.19 203.656L114.752 244.335L231.486 303.551L196.209 373.095L79.4746 313.879L120.153 438.317L46.0298 462.548L5.35093 338.11L-53.865 454.844L-123.409 419.566L-64.1928 302.832L-188.631 343.511L-212.862 269.388L-88.4239 228.709L-205.158 169.493L-169.881 99.949L-53.1464 159.165L-93.8253 34.727L-19.7016 10.4959L20.9773 134.934L80.1932 18.1996L149.737 53.4772Z"
                  fill="url(#paint0_linear_2673_3126)"
                />
              </g>
              <defs>
                <filter
                  id="filter0_f_2673_3126"
                  x="-222.861"
                  y="0.496094"
                  width="472.051"
                  height="472.052"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity={0} result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feGaussianBlur
                    stdDeviation={5}
                    result="effect1_foregroundBlur_2673_3126"
                  />
                </filter>
                <linearGradient
                  id="paint0_linear_2673_3126"
                  x1="-187.519"
                  y1="134.721"
                  x2="213.848"
                  y2="338.323"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset={0} stopColor="var(--primary-color35)" />
                  <stop offset={1} stopColor="var(--primary-color35)" stopOpacity={0} />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div className="tf-container">
            <div className="row justify-content-center">
              <div className="col-md-12 ">
                <div
                  className="tf-heading mb60 wow fadeInUp"
                  style={{ visibility: "visible", animationName: "fadeInUp" }}
                >
                  <h2 className="heading">OUR TEAM</h2>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 ">
                <div className="tf-team">
                  <div className="image">
                    <img src="assets_front/images/team/team-1.jpg" alt="Image" />
                  </div>
                  <h4 className="name">
                    <a href="#">Ralph Edwards</a>
                  </h4>
                  <p className="position">Founder</p>
                  <ul className="social">
                    <li>
                      <a href="#">
                        <i className="fa fa-linkedin" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-telegram" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 ">
                <div className="tf-team ">
                  <div className="image">
                    <img src="assets_front/images/team/team-2.jpg" alt="Image" />
                  </div>
                  <h4 className="name">
                    <a href="#">Jason Smith</a>
                  </h4>
                  <p className="position">Co - Founder</p>
                  <ul className="social">
                    <li>
                      <a href="#">
                        <i className="fa fa-linkedin" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-telegram" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 ">
                <div className="tf-team">
                  <div className="image">
                    <img src="assets_front/images/team/team-3.jpg" alt="Image" />
                  </div>
                  <h4 className="name">
                    <a href="#">Tony Wings</a>
                  </h4>
                  <p className="position">Web Designer</p>
                  <ul className="social">
                    <li>
                      <a href="#">
                        <i className="fa fa-linkedin" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-telegram" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 ">
                <div className="tf-team">
                  <div className="image">
                    <img src="assets_front/images/team/team-4.jpg" alt="Image" />
                  </div>
                  <h4 className="name">
                    <a href="#">Esther Howard</a>
                  </h4>
                  <p className="position">Project Manager</p>
                  <ul className="social">
                    <li>
                      <a href="#">
                        <i className="fa fa-linkedin" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-telegram" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 ">
                <div className="tf-team">
                  <div className="image">
                    <img src="assets_front/images/team/team-5.jpg" alt="Image" />
                  </div>
                  <h4 className="name">
                    <a href="#">Jenny Wilson</a>
                  </h4>
                  <p className="position">Artist</p>
                  <ul className="social">
                    <li>
                      <a href="#">
                        <i className="fa fa-linkedin" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-telegram" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 ">
                <div className="tf-team">
                  <div className="image">
                    <img src="assets_front/images/team/team-6.jpg" alt="Image" />
                  </div>
                  <h4 className="name">
                    <a href="#">Robert Fox</a>
                  </h4>
                  <p className="position">UI/UX Designer</p>
                  <ul className="social">
                    <li>
                      <a href="#">
                        <i className="fa fa-linkedin" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-telegram" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 ">
                <div className="tf-team">
                  <div className="image">
                    <img src="assets_front/images/team/team-7.jpg" alt="Image" />
                  </div>
                  <h4 className="name">
                    <a href="#">Devon Lane</a>
                  </h4>
                  <p className="position">Ux Architect</p>
                  <ul className="social">
                    <li>
                      <a href="#">
                        <i className="fa fa-linkedin" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-telegram" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="tf-faq tf-section tf-collection-ss" id="Faq">
          <div className="tf-container">
            <div className="row">
              <div className="col-md-12 ">
                <div
                  className="tf-heading mb60 wow fadeInUp"
                  style={{ visibility: "visible" }}
                >
                  <h2 className="heading">Frequently Asked <span>Questions</span></h2>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="tf-flat-accordion2">
                  <div className="flat-toggle2 active">
                    <h6 className="toggle-title active">1. How do I get Started with Nexa Global?</h6>
                    <div className="toggle-content" style={{ display: "block" }}>
                      <p>
                      To get started with NEXA GLOBAL, sign up, fund your account, and join the referral program to earn rewards. Explore the user-friendly dashboard to track your growth and engage with the community.
                      </p>
                    </div>
                  </div>
                  <div className="flat-toggle2">
                    <h6 className="toggle-title">2.Nexa Global secure?</h6>
                    <div className="toggle-content">
                      <p>
                      Yes, NEXA GLOBAL is secure. It uses advanced blockchain technology, high-level encryption, and multi-factor authentication to protect user data and assets. Regular security audits and robust cybersecurity measures ensure a safe and trusted platform for all users.
                      </p>
                    </div>
                  </div>
                  <div className="flat-toggle2">
                    <h6 className="toggle-title">3. On which blockchain is Nexa Global based?</h6>
                    <div className="toggle-content">
                      <p>
                      NEXA GLOBAL is based on the BNB Chain, a highly efficient and scalable blockchain known for its low transaction fees and fast processing speeds. By leveraging BNB Chain, NEXA GLOBAL ensures a secure, decentralized environment for its users to engage with the Future World Token, making transactions faster and more cost-effective
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="tf-flat-accordion2">
                  <div className="flat-toggle2">
                    <h6 className="toggle-title active">
                    4. How can I maximize my returns through the Nexa Global project?
                    </h6>
                    <div className="toggle-content" style={{ display: "block" }}>
                      <p>
                      Maximize returns with NEXA GLOBAL by actively participating in the referral program and referring new users. Invest in Future World Tokens for long-term growth and expand your network for higher rewards. Stay engaged with the community and explore diverse investment opportunities to boost earnings.
                      </p>
                    </div>
                  </div>
                  <div className="flat-toggle2">
                    <h6 className="toggle-title">
                    5. IS IT Simple to use
                    </h6>
                    <div className="toggle-content">
                      <p>
                      NEXA GLOBAL offers a simple, intuitive UI/UX interface designed for seamless navigation, ensuring that users of all experience levels can easily manage their accounts and investments. The platform's clean layout and user-friendly features allow for smooth interaction, making it easy to track referrals, view earnings, and engage with the community without technical complexity.
                      </p>
                    </div>
                  </div>
                  {/* <div className="flat-toggle2">
                    <h6 className="toggle-title">What is your contract address ?</h6>
                    <div className="toggle-content">
                      <p>
                      A contract address is a unique identifier for a smart contract on a blockchain like Ethereum. It's represented by a hexadecimal string
                      </p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
  
      <>
        
        <div className="container-fluid py-lg-5 py-3 tf-collection-ss white-papersection" id="contact">
          <div className="container-fluid">
        
            <div className="row align-items-center getsection">
              <div className="col-md-6">
                <div className="headingget">Get involved</div>
                <ul className="socialicons mt-5">
                  <li><a target="_blank" href="#"><span><img src="./assets/image/home/twitter.png" className="w-100 h-100" alt="Twitter"/></span> Twitter</a></li>
                  <li><a target="_blank" href="#"><span><img src="./assets/image/home/facebook.png" className="w-100 h-100" alt="Medium"/></span> Facebook</a></li>
                  <li><a target="_blank" href="#"><span><img src="./assets/image/home/telegram.png" className="w-100 h-100" alt="Telegram"/></span> Telegram</a></li>
                  <li><a target="_blank" href="#"><span><img src="./assets/image/home/instagram.png" className="w-100 h-100" alt="Discord"/></span> Instagram</a></li>
                  <li><a target="_blank" href="#"><span><img src="./assets/image/home/discord.png" className="w-100 h-100" alt="Discord"/></span> Discord</a></li>
                  <li><a target="_blank" href="#"><span><img src="./assets/image/home/youtube.png" className="w-100 h-100" alt="Discord"/></span> Youtube</a></li>
                  <li><a target="_blank" href="#"><span><img src="./assets/image/home/link.png" className="w-100 h-100" alt="Discord"/></span> Linktr</a></li>

                </ul>                  

              </div>
              <div className="col-md-6">
                <div className="card getcard p-sm-5 p-2">
                  <div className="card-body">
                      <div className="card-heading mb-4">Start Trading on Nexa Global</div>
                      <button className="btn getbtn fw-bold py-3 px-3">GET STARTED</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
      </>

      <Footer />
    </div>
  );
};

export default Hero;
